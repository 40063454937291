﻿@import "../variables";
@import "../mixins";

/* Title above table, possibly with a search input */
.table-header {
    margin-bottom: 0;
    background-color: white;
    padding: 20px 15px;
    font-weight: bold;
    height: $tableHeaderHeight;
    @include top-margin-of-gutters(1);

    h4 {
        font-weight: bold;
        margin: 0;
    }

	&.with-search {
		display: flex;
		align-items: center;
		padding-top: 10px;
		padding-bottom: 9px;
		justify-content: flex-end;
		@include top-margin-of-gutters(2);

		h4 {
			margin-right: auto;
		}

		& .form-group {
			margin: 0 15px;
		}
	}
}

/* Input with 'X' inside it */
.input-group.close-inside {
    position: relative;

    .form-control {
        padding-right: 26px;
        width: 300px;
		border-radius: 4px;
		font-size: 14px;
    }

    .close {
        opacity: 1;
        z-index:10;
        font-weight: normal;
        &:hover {
            opacity: .8;
        }
    }

    .input-group-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 7px;
        z-index: 10;

		span {
			height: 18px;
			width: 18px;
			border-radius: 50%;
			background-color: $gray-dark;
			display: block;
			line-height: 18px;
			text-shadow: none;
			color: white;
		}
    }
}

// disable the x button on inputs for IE and Edge browsers
input::-ms-clear {
    display: none;
}

// we cannot override chrome autofill css, so this is done as a hack 
// to make it look disabled.
input[disabled]:-webkit-autofill,
textarea[disabled]:-webkit-autofill,
select[disabled]:-webkit-autofill {
	box-shadow: 0 0 0 1000px lighten($gray-light, 7.5%) inset !important;
}