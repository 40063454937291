%svg-common {
	background: url("../assets/images/sprite.svg") no-repeat;
}

.svg-AlertRed28 {
	@extend %svg-common;
	background-position: 98.6737400530504% 28.615384615384617%;
}

.svg-AlertRed28-dims {
	width: 28px;
	height: 25px;
}

.svg-accountPairingSettings40 {
	@extend %svg-common;
	background-position: 89.58904109589041% 38.50931677018634%;
}

.svg-accountPairingSettings40-dims {
	width: 40px;
	height: 28px;
}

.svg-avatar47 {
	@extend %svg-common;
	background-position: 60.89385474860335% 60.06600660066007%;
}

.svg-avatar47-dims {
	width: 47px;
	height: 47px;
}

.svg-backButton32 {
	@extend %svg-common;
	background-position: 96.875% 19.18238993710692%;
}

.svg-backButton32-dims {
	width: 21px;
	height: 32px;
}

.svg-backButton46 {
	@extend %svg-common;
	background-position: 76.96335078534031% 83.43949044585987%;
}

.svg-backButton46-dims {
	width: 23px;
	height: 36px;
}

.svg-capture46 {
	@extend %svg-common;
	background-position: 13.927576601671309% 85.06493506493507%;
}

.svg-capture46-dims {
	width: 46px;
	height: 42px;
}

.svg-company28 {
	@extend %svg-common;
	background-position: 98.6737400530504% 36.64596273291925%;
}

.svg-company28-dims {
	width: 28px;
	height: 28px;
}

.svg-currentCases38 {
	@extend %svg-common;
	background-position: 69.75476839237058% 83.97435897435898%;
}

.svg-currentCases38-dims {
	width: 38px;
	height: 38px;
}

.svg-doctor28 {
	@extend %svg-common;
	background-position: 98.6737400530504% 45.3416149068323%;
}

.svg-doctor28-dims {
	width: 28px;
	height: 28px;
}

.svg-downloadIcon34 {
	@extend %svg-common;
	background-position: 10.242587601078167% 98.11320754716981%;
}

.svg-downloadIcon34-dims {
	width: 34px;
	height: 32px;
}

.svg-downloadScans22 {
	@extend %svg-common;
	background-position: 97.12793733681463% 87.8419452887538%;
}

.svg-downloadScans22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadScansDark22 {
	@extend %svg-common;
	background-position: 97.12793733681463% 94.22492401215806%;
}

.svg-downloadScansDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadScreenshots34 {
	@extend %svg-common;
	background-position: 19.40700808625337% 97.5%;
}

.svg-downloadScreenshots34-dims {
	width: 34px;
	height: 30px;
}

.svg-downloadSuccess24 {
	@extend %svg-common;
	background-position: 97.63779527559055% 76.87687687687688%;
}

.svg-downloadSuccess24-dims {
	width: 24px;
	height: 17px;
}

.svg-downloadsResources44 {
	@extend %svg-common;
	background-position: 90.58171745152355% 14.516129032258064%;
}

.svg-downloadsResources44-dims {
	width: 44px;
	height: 40px;
}

.svg-education52 {
	@extend %svg-common;
	background-position: 75.35410764872522% 31.147540983606557%;
}

.svg-education52-dims {
	width: 52px;
	height: 45px;
}

.svg-exportGallery34 {
	@extend %svg-common;
	background-position: 97.63779527559055% 54.037267080745345%;
}

.svg-exportGallery34-dims {
	width: 24px;
	height: 28px;
}

.svg-exportSettings34 {
	@extend %svg-common;
	background-position: 100% 10.526315789473685%;
}

.svg-exportSettings34-dims {
	width: 33px;
	height: 27px;
}

.svg-exportSettings50 {
	@extend %svg-common;
	background-position: 74.92957746478874% 60.32258064516129%;
}

.svg-exportSettings50-dims {
	width: 50px;
	height: 40px;
}

.svg-externalLinkAlt22 {
	@extend %svg-common;
	background-position: 85.37859007832898% 86.58536585365853%;
}

.svg-externalLinkAlt22-dims {
	width: 22px;
	height: 22px;
}

.svg-eyeIcon28 {
	@extend %svg-common;
	background-position: 98.6737400530504% 62.732919254658384%;
}

.svg-eyeIcon28-dims {
	width: 28px;
	height: 28px;
}

.svg-home46 {
	@extend %svg-common;
	background-position: 0 100%;
}

.svg-home46-dims {
	width: 38px;
	height: 38px;
}

.svg-icn_pen50 {
	@extend %svg-common;
	background-position: 24.089635854341736% 44.44444444444444%;
}

.svg-icn_pen50-dims {
	width: 48px;
	height: 44px;
}

.svg-iconWechat61 {
	@extend %svg-common;
	background-position: 45.63953488372093% 60.666666666666664%;
}

.svg-iconWechat61-dims {
	width: 61px;
	height: 50px;
}

.svg-imageHubFolder50 {
	@extend %svg-common;
	background-position: 0 87.33333333333333%;
}

.svg-imageHubFolder50-dims {
	width: 50px;
	height: 50px;
}

.svg-info96 {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-info96-dims {
	width: 96px;
	height: 96px;
}

.svg-invisalign40 {
	@extend %svg-common;
	background-position: 89.58904109589041% 45.103857566765576%;
}

.svg-invisalign40-dims {
	width: 40px;
	height: 13px;
}

.svg-iteroHeaderLogo61 {
	@extend %svg-common;
	background-position: 77.32558139534883% 0;
}

.svg-iteroHeaderLogo61-dims {
	width: 61px;
	height: 61px;
}

.svg-iteroLoginAsLogo86 {
	@extend %svg-common;
	background-position: 0 36.36363636363637%;
}

.svg-iteroLoginAsLogo86-dims {
	width: 86px;
	height: 86px;
}

.svg-iteroLoginLogo86 {
	@extend %svg-common;
	background-position: 26.95924764890282% 30.967741935483872%;
}

.svg-iteroLoginLogo86-dims {
	width: 86px;
	height: 40px;
}

.svg-localization48 {
	@extend %svg-common;
	background-position: 37.53501400560224% 43.87096774193548%;
}

.svg-localization48-dims {
	width: 48px;
	height: 40px;
}

.svg-loginSettings40 {
	@extend %svg-common;
	background-position: 86.73740053050398% 53.225806451612904%;
}

.svg-loginSettings40-dims {
	width: 28px;
	height: 40px;
}

.svg-magnifingGlass26 {
	@extend %svg-common;
	background-position: 97.89473684210526% 70.98765432098766%;
}

.svg-magnifingGlass26-dims {
	width: 25px;
	height: 26px;
}

.svg-messages42 {
	@extend %svg-common;
	background-position: 90.08264462809917% 27.331189710610932%;
}

.svg-messages42-dims {
	width: 42px;
	height: 39px;
}

.svg-messages80 {
	@extend %svg-common;
	background-position: 57.23076923076923% 0;
}

.svg-messages80-dims {
	width: 80px;
	height: 75px;
}

.svg-messagesAlert22 {
	@extend %svg-common;
	background-position: 91.12271540469973% 86.06060606060606%;
}

.svg-messagesAlert22-dims {
	width: 22px;
	height: 20px;
}

.svg-more-icon22 {
	@extend %svg-common;
	background-position: 98.74371859296483% 18.429003021148038%;
}

.svg-more-icon22-dims {
	width: 7px;
	height: 19px;
}

.svg-notificationsSettings40 {
	@extend %svg-common;
	background-position: 89.58904109589041% 65.91639871382637%;
}

.svg-notificationsSettings40-dims {
	width: 40px;
	height: 39px;
}

.svg-orders80 {
	@extend %svg-common;
	background-position: 56.19335347432024% 27.77777777777778%;
}

.svg-orders80-dims {
	width: 74px;
	height: 80px;
}

.svg-paperclip24 {
	@extend %svg-common;
	background-position: 96.37305699481865% 100%;
}

.svg-paperclip24-dims {
	width: 19px;
	height: 19px;
}

.svg-patientEdit40 {
	@extend %svg-common;
	background-position: 88.8586956521739% 78.70967741935483%;
}

.svg-patientEdit40-dims {
	width: 37px;
	height: 40px;
}

.svg-patients80 {
	@extend %svg-common;
	background-position: 0 67.4074074074074%;
}

.svg-patients80-dims {
	width: 77px;
	height: 80px;
}

.svg-penIcon20 {
	@extend %svg-common;
	background-position: 58.701298701298704% 46.96969696969697%;
}

.svg-penIcon20-dims {
	width: 20px;
	height: 20px;
}

.svg-pendingCases40 {
	@extend %svg-common;
	background-position: 26.301369863013697% 84.51612903225806%;
}

.svg-pendingCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-plus-icon22 {
	@extend %svg-common;
	background-position: 99.74489795918367% 98.21958456973294%;
}

.svg-plus-icon22-dims {
	width: 13px;
	height: 13px;
}

.svg-practiceSettings45 {
	@extend %svg-common;
	background-position: 100% 0;
}

.svg-practiceSettings45-dims {
	width: 33px;
	height: 34px;
}

.svg-printRx22 {
	@extend %svg-common;
	background-position: 79.3733681462141% 68.9969604863222%;
}

.svg-printRx22-dims {
	width: 22px;
	height: 21px;
}

.svg-printRxDark22 {
	@extend %svg-common;
	background-position: 48.56396866840731% 47.11246200607903%;
}

.svg-printRxDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-printSuccess24 {
	@extend %svg-common;
	background-position: 97.63779527559055% 81.73652694610779%;
}

.svg-printSuccess24-dims {
	width: 24px;
	height: 16px;
}

.svg-privacy100 {
	@extend %svg-common;
	background-position: 30.476190476190474% 0;
}

.svg-privacy100-dims {
	width: 90px;
	height: 90px;
}

.svg-rx38 {
	@extend %svg-common;
	background-position: 72.47956403269755% 71.83544303797468%;
}

.svg-rx38-dims {
	width: 38px;
	height: 34px;
}

.svg-rx52 {
	@extend %svg-common;
	background-position: 75.35410764872522% 46.20462046204621%;
}

.svg-rx52-dims {
	width: 52px;
	height: 47px;
}

.svg-rx80 {
	@extend %svg-common;
	background-position: 23.692307692307693% 65.46762589928058%;
}

.svg-rx80-dims {
	width: 80px;
	height: 72px;
}

.svg-rxSettings40 {
	@extend %svg-common;
	background-position: 37.26027397260274% 84.51612903225806%;
}

.svg-rxSettings40-dims {
	width: 40px;
	height: 40px;
}

.svg-settings45 {
	@extend %svg-common;
	background-position: 90.83333333333333% 0;
}

.svg-settings45-dims {
	width: 45px;
	height: 45px;
}

.svg-systemInformation40 {
	@extend %svg-common;
	background-position: 48.21917808219178% 84.51612903225806%;
}

.svg-systemInformation40-dims {
	width: 40px;
	height: 40px;
}

.svg-totalCases40 {
	@extend %svg-common;
	background-position: 59.178082191780824% 84.51612903225806%;
}

.svg-totalCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-trashIcon21 {
	@extend %svg-common;
	background-position: 53.746770025839794% 47.11246200607903%;
}

.svg-trashIcon21-dims {
	width: 18px;
	height: 21px;
}

.svg-viewer61 {
	@extend %svg-common;
	background-position: 77.32558139534883% 19.303797468354432%;
}

.svg-viewer61-dims {
	width: 61px;
	height: 34px;
}

