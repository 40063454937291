﻿@import "../variables";
@import "../mixins";

.eup-textbox {
    input {
        border: none;
        box-shadow: none;
        border-bottom: 2px solid $gray-dark;
        background-color: $gray-lighter;
        border-radius: 0px;
        padding-top: 20px;

        &:focus {
            border: none;
            box-shadow: none;
            border-bottom: 2px solid $gray-dark;
        }

        &::-webkit-input-placeholder {
            color: $input-color;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $input-color;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: $input-color;
        }

        &:-ms-input-placeholder {
            color: $input-color;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill {
            transition:none;
            box-shadow: 0 0 0 1000px $gray-lighter inset !important;
        }
    }

	label + input, label + div > input {
		padding-top: 5px;
	}

    &.has-error .form-control:focus {
        border-color: $gray-lighter;
        box-shadow: none;
        border-bottom: 2px solid $gray-dark;
    }

    &.has-error .form-control {
        border-color: $gray-lighter;
        box-shadow: none;
        border-bottom: 2px solid $gray-dark;
    }
}