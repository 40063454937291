@mixin eup-link-button-mixin {
  text-decoration: none;
  font-size: 1.4rem;
  line-height: 1;
  color: #18a5f2;
  cursor: pointer;

  &:link, &:visited, &:hover, &:active {
    text-decoration: underline;
    color: #18a5f2;
  }
}

.eup-link-button {
  @include eup-link-button-mixin;
}
